import { EXPERIMENT_KEYS } from '~/constants/experiment-keys'

export default function useExperiment() {
  const { $experiment } = useNuxtApp()
  const { isInCa } = useGeolocation()

  // Only show things to Canadian guests when the feature flag is enabled.
  const showCanadianOwnerBanner = computed(() => $experiment.getVariant(EXPERIMENT_KEYS.rvz22206CanadianOwnedCampaign) && isInCa.value)

  return {
    showCanadianOwnerBanner,
  }
}
